import React from "react";

const Help = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <h3>This page is under development.</h3>
    </div>
  );
};

export default Help;
