//SEARCH
export const SEARCH_API = "/search";

//artist
export const GET_ARTIST_API = "/artist";
export const GET_ARTIST_RELATIONSHIP_API = "/artist/relationships";
export const GET_ARTIST_RELEASE_GROUP_API = "/artist/release-group";
export const GET_ARTIST_RELEASE_API = "/artist/release";
export const GET_ARTIST_TAG_API = "/artist/tag";
export const GET_ARTIST_URL_API = "/artist/url";

//release
export const GET_RELEASE_API = "/release";
export const GET_RELEASE_GROUP_API = "/release-group";
export const GET_RELEASE_GROUP_RELEASE_API = "/release-group/release";
export const GET_RELEASE_GROUP_URL_API = "/release-group/url";
export const GET_RELEASE_GROUP_TAG_API = "/release-group/tag";
export const GET_RELEASE_GROUP_TRACK_API = "/release/track";
export const GET_RELEASE_URL_API = "/release/url";
export const GET_RELEASE_TAG_API = "/release/tag";

export const GET_RECENT_RELEASE_API = "/getRecentlyAddedRelase";
export const GET_RECORDING_API = "/recording";
export const GET_RECORDING_LIST_API = "/recording/list";
export const GET_RECORDING_TAG_API = "/recording//tag";
