import {
  GET_RELEASE,
  GET_RELEASE_SUCCESS,
  GET_RELEASE_FAIL,
  GET_RELEASE_GROUP,
  GET_RELEASE_GROUP_SUCCESS,
  GET_RELEASE_GROUP_FAIL,
  GET_RELEASE_GROUP_RELEASE,
  GET_RELEASE_GROUP_RELEASE_SUCCESS,
  GET_RELEASE_GROUP_RELEASE_FAIL,
  GET_RELEASE_GROUP_URL,
  GET_RELEASE_GROUP_URL_SUCCESS,
  GET_RELEASE_GROUP_URL_FAIL,
  GET_RELEASE_GROUP_TAG,
  GET_RELEASE_GROUP_TAG_SUCCESS,
  GET_RELEASE_GROUP_TAG_FAIL,
  GET_RELEASE_GROUP_TRACK,
  GET_RELEASE_GROUP_TRACK_SUCCESS,
  GET_RELEASE_GROUP_TRACK_FAIL,
  RESET_RELEASE,
  GET_RELEASE_URL,
  GET_RELEASE_URL_SUCCESS,
  GET_RELEASE_URL_FAIL,
  GET_RELEASE_TAG,
  GET_RELEASE_TAG_SUCCESS,
  GET_RELEASE_TAG_FAIL,
  GET_RECENT_RELEASE,
  GET_RECENT_RELEASE_SUCCESS,
  GET_RECENT_RELEASE_FAIL,
  RESET_RECENT_RELEASE,
  GET_NEW_RELEASE,
  GET_NEW_RELEASE_SUCCESS,
  GET_NEW_RELEASE_FAIL,
  RESET_NEW_RELEASE,
  GET_RECENT_RELEASE_FOOTER,
  GET_RECENT_RELEASE_FOOTER_SUCCESS,
  GET_RECENT_RELEASE_FOOTER_FAIL,
  RESET_RECENT_RELEASE_FOOTER,
  RESET_RELEASE_GROUP,
  RESET_GET_RELEASE_GROUP_TAG,
  RESET_GET_RELEASE_GROUP_URL,
  RESET_GET_RELEASE_GROUP_RELEASE,
} from "./actionType";

export const getRelease = (payload) => ({
  type: GET_RELEASE,
  payload: payload,
});

export const getReleaseSuccess = (actionType, data) => ({
  type: GET_RELEASE_SUCCESS,
  payload: { actionType, data },
});

export const getReleaseFail = (actionType, error) => ({
  type: GET_RELEASE_FAIL,
  payload: { actionType, error },
});

export const resetRelease = (actionType, error) => ({
  type: RESET_RELEASE,
});

//tag
export const getReleaseTag = (payload) => ({
  type: GET_RELEASE_TAG,
  payload: payload,
});

export const getReleaseTagSuccess = (actionType, data) => ({
  type: GET_RELEASE_TAG_SUCCESS,
  payload: { actionType, data },
});

export const getReleaseTagFail = (actionType, error) => ({
  type: GET_RELEASE_TAG_FAIL,
  payload: { actionType, error },
});

export const getReleaseUrl = (payload) => ({
  type: GET_RELEASE_URL,
  payload: payload,
});

export const getReleaseUrlSuccess = (actionType, data) => ({
  type: GET_RELEASE_URL_SUCCESS,
  payload: { actionType, data },
});

export const getReleaseUrlFail = (actionType, error) => ({
  type: GET_RELEASE_URL_FAIL,
  payload: { actionType, error },
});

//release-group
export const getReleaseGroup = (payload) => ({
  type: GET_RELEASE_GROUP,
  payload: payload,
});

export const getReleaseGroupSuccess = (actionType, data) => ({
  type: GET_RELEASE_GROUP_SUCCESS,
  payload: { actionType, data },
});

export const getReleaseGroupFail = (actionType, error) => ({
  type: GET_RELEASE_GROUP_FAIL,
  payload: { actionType, error },
});

export const resetReleaseGroup = (actionType, error) => ({
  type: RESET_RELEASE_GROUP,
});

//release-group-release
export const getReleaseGroupRelease = (payload) => ({
  type: GET_RELEASE_GROUP_RELEASE,
  payload: payload,
});

export const getReleaseGroupReleaseSuccess = (actionType, data) => ({
  type: GET_RELEASE_GROUP_RELEASE_SUCCESS,
  payload: { actionType, data },
});

export const getReleaseGroupReleaseFail = (actionType, error) => ({
  type: GET_RELEASE_GROUP_RELEASE_FAIL,
  payload: { actionType, error },
});

export const resetgetReleaseGroupRelease = (actionType, error) => ({
  type: RESET_GET_RELEASE_GROUP_RELEASE,
});

//release-group-url
export const getReleaseGroupUrl = (payload) => ({
  type: GET_RELEASE_GROUP_URL,
  payload: payload,
});

export const getReleaseGroupUrlSuccess = (actionType, data) => ({
  type: GET_RELEASE_GROUP_URL_SUCCESS,
  payload: { actionType, data },
});

export const getReleaseGroupUrlFail = (actionType, error) => ({
  type: GET_RELEASE_GROUP_URL_FAIL,
  payload: { actionType, error },
});

export const resetgetReleaseGroupUrl = (actionType, error) => ({
  type: RESET_GET_RELEASE_GROUP_URL,
});

//release-group-tag
export const getReleaseGroupTag = (payload) => ({
  type: GET_RELEASE_GROUP_TAG,
  payload: payload,
});

export const getReleaseGroupTagSuccess = (actionType, data) => ({
  type: GET_RELEASE_GROUP_TAG_SUCCESS,
  payload: { actionType, data },
});

export const getReleaseGroupTagFail = (actionType, error) => ({
  type: GET_RELEASE_GROUP_TAG_FAIL,
  payload: { actionType, error },
});

export const resetgetReleaseGroupTag = (actionType, error) => ({
  type: RESET_GET_RELEASE_GROUP_TAG,
});

//release-group-track
export const getReleaseGroupTrack = (payload) => ({
  type: GET_RELEASE_GROUP_TRACK,
  payload: payload,
});

export const getReleaseGroupTrackSuccess = (actionType, data) => ({
  type: GET_RELEASE_GROUP_TRACK_SUCCESS,
  payload: { actionType, data },
});

export const getReleaseGroupTrackFail = (actionType, error) => ({
  type: GET_RELEASE_GROUP_TRACK_FAIL,
  payload: { actionType, error },
});

//getRecentRelease
export const getRecentRelease = (payload) => ({
  type: GET_RECENT_RELEASE,
  payload: payload,
});

export const getRecentReleaseSuccess = (actionType, data) => ({
  type: GET_RECENT_RELEASE_SUCCESS,
  payload: { actionType, data },
});

export const getRecentReleaseFail = (actionType, error) => ({
  type: GET_RECENT_RELEASE_FAIL,
  payload: { actionType, error },
});

export const resetRecentRelease = (actionType, error) => ({
  type: RESET_RECENT_RELEASE,
});

//new release
export const getNewRelease = (payload) => ({
  type: GET_NEW_RELEASE,
  payload: payload,
});

export const getNewReleaseSuccess = (actionType, data) => ({
  type: GET_NEW_RELEASE_SUCCESS,
  payload: { actionType, data },
});

export const getNewReleaseFail = (actionType, error) => ({
  type: GET_NEW_RELEASE_FAIL,
  payload: { actionType, error },
});

export const resetNewRelease = (actionType, error) => ({
  type: RESET_NEW_RELEASE,
});

//footer
export const getRecentReleaseFooter = (payload) => ({
  type: GET_RECENT_RELEASE_FOOTER,
  payload: payload,
});

export const getRecentReleaseFooterSuccess = (actionType, data) => ({
  type: GET_RECENT_RELEASE_FOOTER_SUCCESS,
  payload: { actionType, data },
});

export const getRecentReleaseFooterFail = (actionType, error) => ({
  type: GET_RECENT_RELEASE_FOOTER_FAIL,
  payload: { actionType, error },
});

export const resetRecentReleaseFooter = (actionType, error) => ({
  type: RESET_RECENT_RELEASE_FOOTER,
});
