import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import "./Artist.css";
import { CiStar } from "react-icons/ci";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useDispatch, useSelector } from "react-redux";
import { Grid, _ } from "gridjs-react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaInstagram, FaRegStar, FaYoutube } from "react-icons/fa";
import { FaStar } from "react-icons/fa";
import { TbUserPentagon, TbWorld } from "react-icons/tb";
import { useParams } from "react-router-dom";
import {
  getArtist,
  getArtistRelationship,
  getArtistRelease,
  getArtistReleaseGroup,
  getArtistTag,
  getArtistUrl,
  getReleaseGroup,
  getReleaseGroupRelease,
  getReleaseGroupTag,
  getReleaseGroupUrl,
  resetArtist,
  resetGetArtistRelease,
  resetGetArtistReleaseGroup,
  resetReleaseGroup,
  resetgetArtistRelationship,
  resetgetArtistTag,
  resetgetArtistUrl,
} from "../../Store/actions";
import { FaRegStarHalfStroke } from "react-icons/fa6";
import { SiDiscogs } from "react-icons/si";

const Artist = ({ props }) => {
  console.log(props);

  let { gid } = useParams();

  console.log("gid of artist", gid);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const timer = setTimeout(() => {
      let reqdata = {
        artist_gid: gid,
      };
      dispatch(getArtist(reqdata));
      dispatch(getArtistTag(reqdata));
      dispatch(getArtistUrl(reqdata));
      dispatch(getArtistReleaseGroup(reqdata));
    }, 0);

    return () => clearTimeout(timer); // Cleanup on unmount
  }, [gid]); // Empty dependency array means it should only run once

  const typeToIcon = {
    Instagram: <FaInstagram style={{ marginRight: "8px" }} />,
    YouTube: <FaYoutube style={{ marginRight: "8px" }} />,
    Discogs: <SiDiscogs style={{ marginRight: "8px" }} />,
    // Add other types and icons here as needed
  };

  const {
    artistCode,
    artistData,
    artistTagData,
    artistUrlData,
    artistReleaseGroupData,
    artistReleaseData,
    artistRelationShipData,

    artistReleaseCode,
    artistReleaseGroupCode,
    artistTagCode,
    artistRelationShipCode,
    artistUrlCode,
  } = useSelector((state) => ({
    artistData: state.artistReducer.artistData,
    artistTagData: state.artistReducer.artistTagData,
    artistUrlData: state.artistReducer.artistUrlData,
    artistReleaseGroupData: state.artistReducer.artistReleaseGroupData,
    artistReleaseData: state.artistReducer.artistReleaseData,
    artistRelationShipData: state.artistReducer.artistRelationShipData,

    artistCode: state.artistReducer.artistCode,
    artistReleaseCode: state.artistReducer.artistReleaseCode,
    artistReleaseGroupCode: state.artistReducer.artistReleaseGroupCode,
    artistTagCode: state.artistReducer.artistTagCode,
    artistRelationShipCode: state.artistReducer.artistRelationShipCode,
    artistUrlCode: state.artistReducer.artistUrlCode,
  }));

  const [artistInfo, setartistInfo] = useState({
    artist_id: "",
    artist_gid: "",
    name: "",
    sort_name: "",
    artist_type: "",
    gender: "",
    comment: "",
    born: "",
    end: "",
    area: "",
    born_in: "",
    end_area: null,
  });
  const [artistTag, setartistTag] = useState([]);
  const [artistUrl, setartistUrl] = useState([]);
  const [artistReleaseGroup, setartistReleaseGroup] = useState([]);
  const [artistRelease, setartistRelease] = useState([]);
  const [artistRelationship, setartistRelationship] = useState([]);

  console.log("artistRelationShipData=========", artistRelationShipData);

  useEffect(() => {
    if (artistCode == 200) {
      setartistInfo(artistData);
      dispatch(resetArtist());
    }
  }, [artistData]);

  useEffect(() => {
    if (artistRelationShipCode == 200) {
      setartistRelationship(artistRelationShipData);
      dispatch(resetgetArtistRelationship());
    }
  }, [artistRelationShipData]);

  useEffect(() => {
    if (artistTagCode == 200) {
      const groupedData = artistTagData.reduce((result, item) => {
        // Check if the group (item.type) already exists in the result
        if (!result[item.type]) {
          result[item.type] = []; // If not, create an empty array for this group
        }
        // Add the item to the appropriate group
        result[item.type].push(item);
        return result;
      }, {});

      console.log(groupedData);
      // setreleaseGroupTag(groupedData);

      setartistTag(groupedData);
      dispatch(resetgetArtistTag());
    }
  }, [artistTagData]);

  useEffect(() => {
    if (artistUrlCode == 200) {
      setartistUrl(artistUrlData);
      dispatch(resetgetArtistUrl());
    }
  }, [artistUrlData]);

  useEffect(() => {
    if (artistReleaseGroupCode == 200) {
      setartistReleaseGroup(artistReleaseGroupData);
      dispatch(resetGetArtistReleaseGroup());
    }
  }, [artistReleaseGroupData]);

  useEffect(() => {
    if (artistReleaseCode == 200) {
      setartistRelease(artistReleaseData);
      dispatch(resetGetArtistRelease());
    }
  }, [artistReleaseData]);

  console.log("artistInfo==========", artistInfo);
  console.log("artistTag==========", artistTag);
  console.log("artistUrlData==========", artistUrlData);
  console.log("artistReleaseGroupData==========", artistReleaseGroupData);

  function mapStars(count) {
    return Array(count).fill("1");
  }

  const [isIndexSelected, setisIndexSelected] = useState(0);
  const [isRelationClicked, setisRelationClicked] = useState(0);

  const handleTabChange = (index) => {
    console.log("index=============", index);

    if (index == 1 && isIndexSelected == 0) {
      setisIndexSelected(1);
      let reqdata = {
        artist_gid: gid,
      };
      dispatch(getArtistRelease(reqdata));
    }

    if (index == 3 && isRelationClicked == 0) {
      setisRelationClicked(1);
      let reqdata = {
        artist_type: artistInfo.artist_type,
        artist_gid: gid,
      };
      dispatch(getArtistRelationship(reqdata));
    }
  };

  const handleReleaseGroupClick = (cell, row, id) => {
    navigate(`/releaseGroup/${id}`, {
      state: {
        release_group_gid: id,
      },
    });
  };

  const handleReleaseClick = (cell, row, id) => {
    navigate(`/release/${id}`, {
      state: {
        release_gid: id,
      },
    });
  };

  const handleArtistNameClick = (id) => {
    navigate(`/artist/${id}`);
  };

  const calculateStars = (value) => {
    const rating = value / 20; // Convert 0-100 to a 0-5 scale
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 >= 0.5 ? 1 : 0;
    const emptyStars = 5 - fullStars - halfStar;
    return { fullStars, halfStar, emptyStars };
  };

  // Component to render star icons based on the rating
  const StarRating = ({ rating }) => {
    const { fullStars, halfStar, emptyStars } = calculateStars(rating);

    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        {Array(fullStars)
          .fill()
          .map((_, i) => (
            <FaStar key={`full-${i}`} style={{ color: "gold" }} />
          ))}
        {halfStar === 1 && (
          <FaRegStarHalfStroke key="half" style={{ color: "gold" }} />
        )}
        {Array(emptyStars)
          .fill()
          .map((_, i) => (
            <FaRegStar key={`empty-${i}`} style={{ color: "#ddd" }} />
          ))}
      </div>
    );
  };

  return (
    <Row>
      <Col md="2">
        <div className="leftMainDiv">
          <div>
            <p className="infoHeading">Artist Information</p>
            <p className="infoText">
              <strong>Sort name:</strong> {artistInfo.sort_name}
            </p>
            <p className="infoText">
              <strong>Type:</strong> {artistInfo.artist_type}
            </p>
            <p className="infoText">
              <strong>Gender:</strong> {artistInfo.gender}
            </p>
            <p className="infoText">
              <strong>Born:</strong> {artistInfo.born}
            </p>
            <p className="infoText">
              <strong>Born in:</strong> {artistInfo.born_in}
            </p>
            <p className="infoText">
              <strong>Area:</strong> {artistInfo.area}
            </p>
          </div>
          <div>
            <p className="infoHeading">Tags</p>
            {Object.entries(artistTag).map(([groupName, items]) => (
              <div key={groupName} style={{ marginBottom: "10px" }}>
                <strong>{groupName}</strong>
                {items.map((item) => (
                  <p style={{ margin: 0 }} key={item.tag_id}>
                    {item.name}
                  </p>
                ))}
              </div>
            ))}
          </div>
          <div>
            <p className="infoHeading">External Links</p>
            {Array.isArray(artistUrl) &&
              artistUrl.map((i, k) => (
                <a
                  href={i.url}
                  style={{ textDecoration: "none", color: "black" }}
                  target="_blank"
                >
                  {/* {typeToIcon[i.type] || <TbWorld />} */}
                  <p className="linkHover" style={{ margin: 0 }} key={k}>
                    {i.type}
                  </p>
                </a>
              ))}
          </div>
        </div>
      </Col>

      <Col md="10" style={{ borderLeft: "1px solid #ccc" }}>
        <div className="nameDiv">
          <TbUserPentagon size={"40px"} color="#5B7EF0" />
          <div>
            <strong className="artistName">{artistInfo.name}</strong>
            <p style={{ margin: 0 }}>~{artistInfo.artist_type}</p>
          </div>
        </div>
        <Tabs
          style={{ marginTop: "30px" }}
          defaultIndex={0}
          onSelect={(index) => handleTabChange(index)}
        >
          <TabList>
            <Tab>Overview</Tab>
            <Tab>Releases</Tab>
            <Tab>Tags</Tab>
            <Tab>Members</Tab>
          </TabList>
          <TabPanel>
            <div>
              <p
                style={{
                  color: "rgb(91, 126, 240)",
                  marginTop: "15px",
                  marginBottom: "15px",
                  fontWeight: "700",
                  fontSize: "20px",
                }}
              >
                Discography
              </p>
              <Grid
                data={artistReleaseGroup}
                columns={[
                  {
                    id: "gid",
                    name: "gid",
                    hidden: true,
                  },
                  {
                    id: "artist_credit_names",
                    name: "artist_credit_names",
                    hidden: true,
                  },
                  {
                    id: "first_release_date_year",
                    name: "Year",
                  },
                  {
                    id: "name",
                    name: "Title",
                    formatter: (cell, row) =>
                      _(
                        <div>
                          <p
                            className="linkHover"
                            style={{ cursor: "pointer", margin: 0 }}
                            onClick={() =>
                              handleReleaseGroupClick(
                                cell,
                                row,
                                row.cells[0]["data"]
                              )
                            }
                          >
                            {cell}
                          </p>
                        </div>
                      ),
                  },
                  {
                    id: "Artist",
                    name: "Artist",
                    width: "400px",
                    formatter: (cell, row) =>
                      _(
                        <div>
                          <p style={{ cursor: "pointer", margin: 0 }}>
                            {row.cells[1]["data"].map((i, k) => (
                              <>
                                <label
                                  className="linkHover"
                                  style={{ cursor: "pointer", margin: 0 }}
                                  key={k}
                                  onClick={() =>
                                    handleArtistNameClick(i.artist_gid)
                                  }
                                >
                                  {i.name}
                                  {k != row.cells[1]["data"].length - 1 && ", "}
                                </label>
                              </>
                            ))}
                          </p>
                        </div>
                      ),
                  },
                  {
                    id: "rating",
                    name: "Rating",
                    formatter: (cell, row) =>
                      _(
                        <div>
                          <StarRating rating={cell} />{" "}
                        </div>
                      ),
                  },
                  {
                    id: "release_count",
                    name: "Releases",
                  },
                ]}
                search={true}
                // sort={true}
                // height={height}
                // style={{ height: { height }, overflowY: "scroll" }}
                pagination={{ enabled: true, limit: 25 }}
                autoWidth={true}
              />
            </div>
          </TabPanel>
          <TabPanel>
            <div>
              <p
                style={{
                  color: "rgb(91, 126, 240)",
                  marginTop: "15px",
                  marginBottom: "15px",
                  fontWeight: "700",
                  fontSize: "20px",
                }}
              >
                Releases
              </p>
              <Grid
                data={artistRelease}
                columns={[
                  {
                    id: "gid",
                    name: "gid",
                    hidden: true,
                  },
                  {
                    id: "artist_credit_names",
                    name: "artist_credit_names",
                    hidden: true,
                  },
                  {
                    id: "country_name",
                    name: "country_name",
                    hidden: true,
                  },
                  {
                    id: "date_year",
                    name: "date_year",
                    hidden: true,
                  },
                  {
                    id: "date_month",
                    name: "date_month",
                    hidden: true,
                  },
                  {
                    id: "date_day",
                    name: "date_day",
                    hidden: true,
                  },
                  {
                    id: "name",
                    name: "Release",
                    formatter: (cell, row) =>
                      _(
                        <div>
                          <p
                            className="linkHover"
                            style={{ cursor: "pointer", margin: 0 }}
                            onClick={() =>
                              handleReleaseClick(
                                cell,
                                row,
                                row.cells[0]["data"]
                              )
                            }
                          >
                            {cell}
                          </p>
                        </div>
                      ),
                  },

                  {
                    id: "name",
                    name: "Artist",
                    formatter: (cell, row) =>
                      _(
                        <div>
                          <p style={{ cursor: "pointer", margin: 0 }}>
                            {row.cells[1]["data"].map((i, k) => (
                              <>
                                <label
                                  className="linkHover"
                                  style={{ cursor: "pointer", margin: 0 }}
                                  key={k}
                                  onClick={() =>
                                    handleArtistNameClick(i.artist_gid)
                                  }
                                >
                                  {i.name}
                                  {k != row.cells[1]["data"].length - 1 && ", "}
                                </label>
                              </>
                            ))}
                          </p>
                        </div>
                      ),
                  },
                  {
                    id: "format",
                    name: "Format",
                  },
                  {
                    id: "track_count",
                    name: "Tracks",
                  },
                  {
                    id: "Country/Date",
                    name: "Country/Date",
                    formatter: (cell, row) =>
                      _(
                        <div>
                          <p style={{ margin: 0 }}>
                            {row.cells[2]["data"]} {row.cells[3]["data"]}-
                            {row.cells[4]["data"]}-{row.cells[5]["data"]}
                          </p>
                        </div>
                      ),
                  },
                  {
                    id: "label",
                    name: "Label",
                  },
                  {
                    id: "barcode",
                    name: "Barcode",
                  },
                ]}
                search={true}
                pagination={{ enabled: true, limit: 25 }}
                autoWidth={true}
              />
            </div>
          </TabPanel>
          <TabPanel>
            <p className="infoHeading">Tags</p>
            {Object.entries(artistTag).map(([groupName, items]) => (
              <div key={groupName} style={{ marginBottom: "10px" }}>
                <strong>{groupName}</strong>
                {items.map((item) => (
                  <p style={{ margin: 0 }} key={item.tag_id}>
                    {item.name}
                  </p>
                ))}
              </div>
            ))}
          </TabPanel>
          <TabPanel>
            <p className="infoHeading">Members</p>
            <div>
              {artistRelationship.map((i, k) => (
                <p>
                  <span
                    className="linkHover"
                    style={{
                      cursor: "pointer",
                      color: "#007bff",
                      fontWeight: "500",
                    }}
                    onClick={() => handleArtistNameClick(i.gid)}
                  >
                    {i.name}
                  </span>{" "}
                  {i.comment && (
                    <span style={{ color: "grey" }}>({i.comment}) </span>
                  )}
                  {i.begin_date_year &&
                    i.begin_date_month &&
                    i.begin_date_day && (
                      <span>
                        ({i.begin_date_year}-{i.begin_date_month}-
                        {i.begin_date_day})
                      </span>
                    )}
                </p>
              ))}
            </div>
          </TabPanel>
        </Tabs>
      </Col>
    </Row>
  );
};

export default Artist;
