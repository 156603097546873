import {
  GET_SEARCH,
  GET_SEARCH_SUCCESS,
  GET_SEARCH_FAIL,
  RESET_SEARCH,
} from "./actionType";

export const getSearch = (payload) => ({
  type: GET_SEARCH,
  payload: payload,
});

export const getSearchSuccess = (actionType, data) => ({
  type: GET_SEARCH_SUCCESS,
  payload: { actionType, data },
});

export const getSearchFail = (actionType, error) => ({
  type: GET_SEARCH_FAIL,
  payload: { actionType, error },
});

export const resetSearch = () => ({
  type: RESET_SEARCH,
});
