import { combineReducers } from "redux";

// Front
import artistReducer from "./ArtistMusic/reducer";
import releaseReducer from "./ReleaseMusic/reducer";
import searchReducer from "./Search/reducer";
import recordingReducer from "./Recording/reducer";

const rootReducer = combineReducers({
  artistReducer,
  releaseReducer,
  searchReducer,
  recordingReducer,
});

export default rootReducer;
