export const GET_ARTIST = "GET_ARTIST";
export const GET_ARTIST_SUCCESS = "GET_ARTIST_SUCCESS";
export const GET_ARTIST_FAIL = "GET_ARTIST_FAIL";
export const RESET_GET_ARTIST = "RESET_GET_ARTIST";

export const GET_ARTIST_RELEASE_GROUP = "GET_ARTIST_RELEASE_GROUP";
export const GET_ARTIST_RELEASE_GROUP_SUCCESS =
  "GET_ARTIST_RELEASE_GROUP_SUCCESS";
export const GET_ARTIST_RELEASE_GROUP_FAIL = "GET_ARTIST_RELEASE_GROUP_FAIL";
export const RESET_GET_ARTIST_RELEASE_GROUP = "RESET_GET_ARTIST_RELEASE_GROUP";

export const GET_ARTIST_RELEASE = "GET_ARTIST_RELEASE";
export const GET_ARTIST_RELEASE_SUCCESS = "GET_ARTIST_RELEASE_SUCCESS";
export const GET_ARTIST_RELEASE_FAIL = "GET_ARTIST_RELEASE_FAIL";
export const RESET_GET_ARTIST_RELEASE = "RESET_GET_ARTIST_RELEASE";

export const GET_ARTIST_URL = "GET_ARTIST_URL";
export const GET_ARTIST_URL_SUCCESS = "GET_ARTIST_URL_SUCCESS";
export const GET_ARTIST_URL_FAIL = "GET_ARTIST_URL_FAIL";
export const RESET_GET_ARTIST_URL = "RESET_GET_ARTIST_URL";

export const GET_ARTIST_TAG = "GET_ARTIST_TAG";
export const GET_ARTIST_TAG_SUCCESS = "GET_ARTIST_TAG_SUCCESS";
export const GET_ARTIST_TAG_FAIL = "GET_ARTIST_TAG_FAIL";
export const RESET_GET_ARTIST_TAG = "RESET_GET_ARTIST_TAG";

export const GET_ARTIST_RELATIONSHIP = "GET_ARTIST_RELATIONSHIP";
export const GET_ARTIST_RELATIONSHIP_SUCCESS =
  "GET_ARTIST_RELATIONSHIP_SUCCESS";
export const GET_ARTIST_RELATIONSHIP_FAIL = "GET_ARTIST_RELATIONSHIP_FAIL";
export const RESET_GET_ARTIST_RELATIONSHIP = "RESET_GET_ARTIST_RELATIONSHIP";
