import {
  GET_RECORDING,
  GET_RECORDING_SUCCESS,
  GET_RECORDING_FAIL,
  GET_RECORDING_LIST,
  GET_RECORDING_LIST_SUCCESS,
  GET_RECORDING_LIST_FAIL,
  RESET_RECORDING,
  RESET_RECORDING_LIST,
  GET_RECORDING_TAG,
  GET_RECORDING_TAG_SUCCESS,
  GET_RECORDING_TAG_FAIL,
  RESET_RECORDING_TAG,
} from "./actionType";

const INIT_STATE = {
  recordingData: [],
  recordingCode: 0,
  recordingListData: [],
  recordingListCode: 0,
  recordingTagData: [],
  recordingTagCode: 0,
};

const recordingReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_RECORDING_SUCCESS:
      console.log(
        "action.payload.actionType=======",
        action.payload.actionType
      );
      switch (action.payload.actionType) {
        case GET_RECORDING:
          console.log(
            "action.payload.actionType action.payload.data=======",
            action.payload.data
          );
          return {
            ...state,
            recordingData: action.payload.data.data,
            recordingCode: action.payload.data.code,
          };
        default:
          return { ...state };
      }
    case GET_RECORDING_FAIL:
      switch (action.payload.actionType) {
        case GET_RECORDING:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }

    case RESET_RECORDING:
      return {
        ...state,
        recordingData: [],
        recordingCode: 0,
      };

    //GET_RECORDING_LIST
    case GET_RECORDING_LIST_SUCCESS:
      switch (action.payload.actionType) {
        case GET_RECORDING_LIST:
          return {
            ...state,
            recordingListData: action.payload.data.data,
            recordingListCode: action.payload.data.code,
          };
        default:
          return { ...state };
      }
    case GET_RECORDING_LIST_FAIL:
      switch (action.payload.actionType) {
        case GET_RECORDING_LIST:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }

    case RESET_RECORDING_LIST:
      return {
        ...state,
        recordingListData: [],
        recordingListCode: 0,
      };

    //tag
    case GET_RECORDING_TAG_SUCCESS:
      switch (action.payload.actionType) {
        case GET_RECORDING_TAG:
          return {
            ...state,
            recordingTagData: action.payload.data.data,
            recordingTagCode: action.payload.data.code,
          };
        default:
          return { ...state };
      }
    case GET_RECORDING_TAG_FAIL:
      switch (action.payload.actionType) {
        case GET_RECORDING_TAG:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }

    case RESET_RECORDING_LIST:
      return {
        ...state,
        recordingListData: [],
        recordingListCode: 0,
      };

    default:
      return state;
  }
};

export default recordingReducer;
