export const GET_RELEASE = "GET_RELEASE";
export const GET_RELEASE_SUCCESS = "GET_RELEASE_SUCCESS";
export const GET_RELEASE_FAIL = "GET_RELEASE_FAIL";
export const RESET_RELEASE = "RESET_RELEASE";

export const GET_RELEASE_URL = "GET_RELEASE_URL";
export const GET_RELEASE_URL_SUCCESS = "GET_RELEASE_URL_SUCCESS";
export const GET_RELEASE_URL_FAIL = "GET_RELEASE_URL_FAIL";
export const RESET_GET_RELEASE_URL = "RESET_GET_RELEASE_URL";

export const GET_RELEASE_TAG = "GET_RELEASE_TAG";
export const GET_RELEASE_TAG_SUCCESS = "GET_RELEASE_TAG_SUCCESS";
export const GET_RELEASE_TAG_FAIL = "GET_RELEASE_TAG_FAIL";

export const GET_RELEASE_GROUP = "GET_RELEASE_GROUP";
export const GET_RELEASE_GROUP_SUCCESS = "GET_RELEASE_GROUP_SUCCESS";
export const GET_RELEASE_GROUP_FAIL = "GET_RELEASE_GROUP_FAIL";
export const RESET_RELEASE_GROUP = "RESET_RELEASE_GROUP";

export const GET_RELEASE_GROUP_RELEASE = "GET_RELEASE_GROUP_RELEASE";
export const GET_RELEASE_GROUP_RELEASE_SUCCESS =
  "GET_RELEASE_GROUP_RELEASE_SUCCESS";
export const GET_RELEASE_GROUP_RELEASE_FAIL = "GET_RELEASE_GROUP_RELEASE_FAIL";
export const RESET_GET_RELEASE_GROUP_RELEASE =
  "RESET_GET_RELEASE_GROUP_RELEASE";

export const GET_RELEASE_GROUP_URL = "GET_RELEASE_GROUP_URL";
export const GET_RELEASE_GROUP_URL_SUCCESS = "GET_RELEASE_GROUP_URL_SUCCESS";
export const GET_RELEASE_GROUP_URL_FAIL = "GET_RELEASE_GROUP_URL_FAIL";
export const RESET_GET_RELEASE_GROUP_URL = "RESET_GET_RELEASE_GROUP_URL";

export const GET_RELEASE_GROUP_TAG = "GET_RELEASE_GROUP_TAG";
export const GET_RELEASE_GROUP_TAG_SUCCESS = "GET_RELEASE_GROUP_TAG_SUCCESS";
export const GET_RELEASE_GROUP_TAG_FAIL = "GET_RELEASE_GROUP_TAG_FAIL";
export const RESET_GET_RELEASE_GROUP_TAG = "RESET_GET_RELEASE_GROUP_TAG";

export const GET_RELEASE_GROUP_TRACK = "GET_RELEASE_GROUP_TRACK";
export const GET_RELEASE_GROUP_TRACK_SUCCESS =
  "GET_RELEASE_GROUP_TRACK_SUCCESS";
export const GET_RELEASE_GROUP_TRACK_FAIL = "GET_RELEASE_GROUP_TRACK_FAIL";

export const GET_RECENT_RELEASE = "GET_RECENT_RELEASE";
export const GET_RECENT_RELEASE_SUCCESS = "GET_RECENT_RELEASE_SUCCESS";
export const GET_RECENT_RELEASE_FAIL = "GET_RECENT_RELEASE_FAIL";
export const RESET_RECENT_RELEASE = "RESET_RECENT_RELEASE";

export const GET_RECENT_RELEASE_FOOTER = "GET_RECENT_RELEASE_FOOTER";
export const GET_RECENT_RELEASE_FOOTER_SUCCESS =
  "GET_RECENT_RELEASE_FOOTER_SUCCESS";
export const GET_RECENT_RELEASE_FOOTER_FAIL = "GET_RECENT_RELEASE_FOOTER_FAIL";
export const RESET_RECENT_RELEASE_FOOTER = "RESET_RECENT_RELEASE_FOOTER";

export const GET_NEW_RELEASE = "GET_NEW_RELEASE";
export const GET_NEW_RELEASE_SUCCESS = "GET_NEW_RELEASE_SUCCESS";
export const GET_NEW_RELEASE_FAIL = "GET_NEW_RELEASE_FAIL";
export const RESET_NEW_RELEASE = "RESET_NEW_RELEASE";
