import { all, fork } from "redux-saga/effects";

import artistSaga from "./ArtistMusic/saga";
import searchSaga from "./Search/saga";
import releaseSaga from "./ReleaseMusic/saga";
import recordingSaga from "./Recording/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(artistSaga),
    fork(releaseSaga),
    fork(searchSaga),
    fork(recordingSaga),
  ]);
}
