import {
  GET_ARTIST,
  GET_ARTIST_SUCCESS,
  GET_ARTIST_FAIL,
  RESET_GET_ARTIST,
  GET_ARTIST_RELEASE,
  GET_ARTIST_RELEASE_SUCCESS,
  GET_ARTIST_RELEASE_FAIL,
  GET_ARTIST_RELEASE_GROUP,
  GET_ARTIST_RELEASE_GROUP_SUCCESS,
  GET_ARTIST_RELEASE_GROUP_FAIL,
  GET_ARTIST_URL,
  GET_ARTIST_URL_SUCCESS,
  GET_ARTIST_URL_FAIL,
  GET_ARTIST_TAG,
  GET_ARTIST_TAG_SUCCESS,
  GET_ARTIST_TAG_FAIL,
  GET_ARTIST_RELATIONSHIP,
  GET_ARTIST_RELATIONSHIP_SUCCESS,
  GET_ARTIST_RELATIONSHIP_FAIL,
  RESET_GET_ARTIST_RELEASE,
  RESET_GET_ARTIST_RELEASE_GROUP,
  RESET_GET_ARTIST_URL,
  RESET_GET_ARTIST_TAG,
  RESET_GET_ARTIST_RELATIONSHIP,
} from "./actionType";

//getArtist
export const getArtist = (payload) => ({
  type: GET_ARTIST,
  payload: payload,
});

export const getArtistSuccess = (actionType, data) => ({
  type: GET_ARTIST_SUCCESS,
  payload: { actionType, data },
});

export const getArtistFail = (actionType, error) => ({
  type: GET_ARTIST_FAIL,
  payload: { actionType, error },
});

export const resetArtist = () => ({
  type: RESET_GET_ARTIST,
});

//getArtistRelease
export const getArtistRelease = (payload) => ({
  type: GET_ARTIST_RELEASE,
  payload: payload,
});

export const getArtistReleaseSuccess = (actionType, data) => ({
  type: GET_ARTIST_RELEASE_SUCCESS,
  payload: { actionType, data },
});

export const getArtistReleaseFail = (actionType, error) => ({
  type: GET_ARTIST_RELEASE_FAIL,
  payload: { actionType, error },
});

export const resetGetArtistRelease = () => ({
  type: RESET_GET_ARTIST_RELEASE,
});

//getArtistReleaseGroup
export const getArtistReleaseGroup = (payload) => ({
  type: GET_ARTIST_RELEASE_GROUP,
  payload: payload,
});

export const getArtistReleaseGroupSuccess = (actionType, data) => ({
  type: GET_ARTIST_RELEASE_GROUP_SUCCESS,
  payload: { actionType, data },
});

export const getArtistReleaseGroupFail = (actionType, error) => ({
  type: GET_ARTIST_RELEASE_GROUP_FAIL,
  payload: { actionType, error },
});

export const resetGetArtistReleaseGroup = () => ({
  type: RESET_GET_ARTIST_RELEASE_GROUP,
});

//getArtistUrl
export const getArtistUrl = (payload) => ({
  type: GET_ARTIST_URL,
  payload: payload,
});

export const getArtistUrlSuccess = (actionType, data) => ({
  type: GET_ARTIST_URL_SUCCESS,
  payload: { actionType, data },
});

export const getArtistUrlFail = (actionType, error) => ({
  type: GET_ARTIST_URL_FAIL,
  payload: { actionType, error },
});

export const resetgetArtistUrl = () => ({
  type: RESET_GET_ARTIST_URL,
});

//getArtistTag
export const getArtistTag = (payload) => ({
  type: GET_ARTIST_TAG,
  payload: payload,
});

export const getArtistTagSuccess = (actionType, data) => ({
  type: GET_ARTIST_TAG_SUCCESS,
  payload: { actionType, data },
});

export const getArtistTagFail = (actionType, error) => ({
  type: GET_ARTIST_TAG_FAIL,
  payload: { actionType, error },
});

export const resetgetArtistTag = () => ({
  type: RESET_GET_ARTIST_TAG,
});

//getArtistRelationship
export const getArtistRelationship = (payload) => ({
  type: GET_ARTIST_RELATIONSHIP,
  payload: payload,
});

export const getArtistRelationshipSuccess = (actionType, data) => ({
  type: GET_ARTIST_RELATIONSHIP_SUCCESS,
  payload: { actionType, data },
});

export const getArtistRelationshipFail = (actionType, error) => ({
  type: GET_ARTIST_RELATIONSHIP_FAIL,
  payload: { actionType, error },
});

export const resetgetArtistRelationship = () => ({
  type: RESET_GET_ARTIST_RELATIONSHIP,
});
