import React, { useEffect, useState } from "react";
import CommonNewGrid from "../../Component/CommonNewGrid";
import { useDispatch, useSelector } from "react-redux";
import { Grid, _ } from "gridjs-react";
import "gridjs/dist/theme/mermaid.css";
import {
  getArtist,
  getArtistRelease,
  getArtistReleaseGroup,
  getArtistTag,
  getArtistUrl,
  resetArtist,
  resetSearch,
} from "../../Store/actions";
import { Card, CardBody } from "reactstrap";
import { useNavigate } from "react-router-dom";

const SearchArtist = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { searchData, searchCode, artistCode, searchType } = useSelector(
    (state) => ({
      searchData: state.searchReducer.searchData,
      searchCode: state.searchReducer.searchCode,
      searchType: state.searchReducer.searchType,
      artistCode: state.artistReducer.artistCode,
    })
  );

  const [artistSearchData, setartistSearchData] = useState([]);

  console.log("artistSearchData==========", artistSearchData);

  useEffect(() => {
    if (Array.isArray(searchData) && searchType == "artist") {
      setartistSearchData(searchData);
      dispatch(resetSearch());
    }
  }, [searchData]);

  const [artistName, setartistName] = useState(null);
  const [artistType, setartistType] = useState(null);
  const [artistId, setartistId] = useState(null);

  const handleArtistClick = (cell, row, id, type) => {
    navigate(`/artist/${id}`, {
      state: {
        artistName: cell,
        artistType: type,
        artistId: id,
      },
    });

    setartistName(cell);
    setartistType(type);
    setartistId(id);
  };

  const BaseExample = () => {
    return (
      <React.Fragment>
        <Grid
          data={artistSearchData}
          columns={[
            {
              id: "artist_gid",
              name: "id",
              hidden: true,
            },
            {
              id: "artist_name",
              name: "Name",
              formatter: (cell, row) =>
                _(
                  <div>
                    <p
                      style={{ cursor: "pointer", margin: 0 }}
                      className="linkHover"
                      onClick={() =>
                        handleArtistClick(
                          cell,
                          row,
                          row.cells[0]["data"],
                          row.cells[3]["data"]
                        )
                      }
                    >
                      {cell}
                    </p>
                  </div>
                ),
            },
            {
              id: "artist_short_name",
              name: "Sort Name",
            },
            {
              id: "artist_type",
              name: "Type",
            },
            {
              id: "gender",
              name: "Gender",
            },
            {
              id: "area_name",
              name: "Area",
            },
            {
              id: "begin",
              name: "Begin",
            },
            {
              id: "area_name",
              name: "Begin Area",
            },
            {
              id: "enda",
              name: "End",
            },
            {
              id: "end_area",
              name: "End Area",
            },
          ]}
          search={true}
          // sort={true}
          // height={height}
          // style={{ height: { height }, overflowY: "scroll" }}
          pagination={{ enabled: true, limit: 25 }}
        />
      </React.Fragment>
    );
  };

  return (
    <>
      <div>
        <h3>Search Result</h3>
      </div>
      <Card>
        <CardBody>
          <BaseExample />
        </CardBody>
      </Card>
    </>
  );
};

export default SearchArtist;
