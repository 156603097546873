import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function ImageWithFallback({ src, alt, style, item, ...props }) {
  const [hasError, setHasError] = useState(false);
  const navigate = useNavigate();
  // Helper to generate random colors
  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  // Generate a random gradient
  function getRandomGradient() {
    const color1 = getRandomColor();
    const color2 = getRandomColor();
    return `linear-gradient(135deg, ${color1}, ${color2})`;
  }

  const gradientBackground = getRandomGradient();

  const handleClick = () => {
    navigate(`/release/${item.gid}`, {
      state: {
        release_gid: item.gid,
      },
    });
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: gradientBackground,
        color: "#fff",
        fontSize: "20px",
        height: "100%",
        width: "100%",
        textAlign: "center",
        boxShadow:
          "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
        cursor: "pointer",
        ...style,
      }}
      onClick={() => handleClick()}
    >
      {!hasError ? (
        <img
          className="image"
          src={src}
          alt={alt}
          onError={() => setHasError(true)}
          {...props}
        />
      ) : (
        <span style={{ fontSize: "20px", fontWeight: "600" }}>{alt}</span>
      )}
    </div>
  );
}

export default ImageWithFallback;
