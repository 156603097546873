import { APIClient, setAuthorization } from "./api_helper";
import * as url from "./url_helper";

const api = new APIClient();
// Gets the logged in user data from local session

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
  const user = localStorage.getItem("authUser");

  console.log("token 123,", user);

  if (user) {
    //setAuthorization("eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiZmJlMWE2YTA2Yzg0Y2JjYTRhMDBjMjQ1NjJiZGQyNDJjZjk3YTAyNzEwYjI5MWYyNjJhM2RmMzQyN2U2OWEzMjdkZDgyOTZiN2E3NDJlMmMiLCJpYXQiOjE2NTUyODU3OTAuNDg0ODI1LCJuYmYiOjE2NTUyODU3OTAuNDg0ODMsImV4cCI6MTY4NjgyMTc5MC40MzY1NjQsInN1YiI6IjEiLCJzY29wZXMiOltdfQ.F4aBSchVdWgtvi75G07ijWt8colXHDpqdaRlgPtJeFYN5ybvEUr9hW44BR9Tz_qaAJ2wHacxmel1vOF01HsH5QarTyoVTWjgyZVSyqZPRZcizMDhIDLLVWmb7bfiyovpQbyDol0EOJscYNCd128draf7ITO0qNdbDWu3t_JSa9jY_qe4eJP5bBhO-C8HkpscFvEBLz8vgXrfKVwGzG3e43k57X8LDOO0RH2L9fmLTUoi0DiqGZgXJEMSbqssfOXsdOAiXnmptWsgZ3uCun8pB5He4TtK-QMXnTJsAxccHFUPnl8xdc2ofeC8d-ykWYicy-xP61BiKBCwZelkM1-_WYZ147jvCTrb1oGPDZE7gHhuQ5OB-Uf0eRP9gZlb8bRTrKzk_AMtHdeS-0sP8OaDiZyG7_hfA5jR-QMWikadI-QbwPouddeLhlYtUSQTKDAFKu0A7tFuB-d9e2SH5iiF0uXATxrriy2csmg7ADKbgMLImX4bOdRPlaPYh9I5ik00oehtquQTGB0fyQcpejMHbIbrXepiQ9l_cgC55HYnNeqFpwBT_wmzvRxc0OQ0OSBuPK_9NWJ8vnQ8kv4Ud64eHVhugAZ9SbOEGZtb7fRy6RZczAjUQstAI5DTww41qHYi0T6JPu5zQc5bsEXhKh4Q-xypP8i3X2FRoo0KfFXTKi0")
    return JSON.parse(user);
  }
  return null;
};

if (localStorage.getItem("authUser")) {
  const users = localStorage.getItem("authUser");
  const users1 = JSON.parse(users);
  console.log("token", users1.access_token);
  setAuthorization(users1.access_token);
}

// //is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

//search
export const searchApi = (requserdata) => api.get(url.SEARCH_API, requserdata);
//artist
export const getArtistApi = (requserdata) =>
  api.get(url.GET_ARTIST_API, requserdata);
export const getArtistRelationShipApi = (requserdata) =>
  api.get(url.GET_ARTIST_RELATIONSHIP_API, requserdata);

export const getArtistReleaseGroupApi = (requserdata) =>
  api.get(url.GET_ARTIST_RELEASE_GROUP_API, requserdata);
export const getArtistReleaseApi = (requserdata) =>
  api.get(url.GET_ARTIST_RELEASE_API, requserdata);
export const getArtistTagApi = (requserdata) =>
  api.get(url.GET_ARTIST_TAG_API, requserdata);
export const getArtistUrlApi = (requserdata) =>
  api.get(url.GET_ARTIST_URL_API, requserdata);

//release
export const getReleaseApi = (requserdata) =>
  api.get(url.GET_RELEASE_API, requserdata);
export const getReleaseUrlApi = (requserdata) =>
  api.get(url.GET_RELEASE_URL_API, requserdata);
export const getReleaseTagApi = (requserdata) =>
  api.get(url.GET_RELEASE_TAG_API, requserdata);

export const getReleaseGroupApi = (requserdata) =>
  api.get(url.GET_RELEASE_GROUP_API, requserdata);
export const getReleaseGroupReleaseApi = (requserdata) =>
  api.get(url.GET_RELEASE_GROUP_RELEASE_API, requserdata);
export const getReleaseGroupUrlApi = (requserdata) =>
  api.get(url.GET_RELEASE_GROUP_URL_API, requserdata);
export const getReleaseGroupTagApi = (requserdata) =>
  api.get(url.GET_RELEASE_GROUP_TAG_API, requserdata);
export const getReleaseGroupTrackApi = (requserdata) =>
  api.get(url.GET_RELEASE_GROUP_TRACK_API, requserdata);

export const getRecentReleaseApi = (requserdata) =>
  api.get(url.GET_RECENT_RELEASE_API, requserdata);

export const getRecordingApi = (requserdata) =>
  api.get(url.GET_RECORDING_API, requserdata);
export const getRecordingListApi = (requserdata) =>
  api.get(url.GET_RECORDING_LIST_API, requserdata);
export const getRecordingTagApi = (requserdata) =>
  api.get(url.GET_RECORDING_TAG_API, requserdata);
