import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import {
  GET_RECORDING,
  GET_RECORDING_LIST,
  GET_RECORDING_TAG,
} from "./actionType";

import {
  getRecordingSuccess,
  getRecordingFail,
  getRecordingListSuccess,
  getRecordingListFail,
  getRecordingTagSuccess,
  getRecordingTagFail,
} from "./action";

import {
  getRecordingApi,
  getRecordingListApi,
  getRecordingTagApi,
} from "../../helpers/soundWave_helper";

function* getRecordingSaga({ payload: rqeuserdata }) {
  try {
    const response = yield call(getRecordingApi, rqeuserdata);
    console.log(response);
    yield put(getRecordingSuccess(GET_RECORDING, response));
  } catch (error) {
    yield put(getRecordingFail(error));
  }
}

function* getRecordingListSaga({ payload: rqeuserdata }) {
  try {
    const response = yield call(getRecordingListApi, rqeuserdata);
    console.log(response);
    yield put(getRecordingListSuccess(GET_RECORDING_LIST, response));
  } catch (error) {
    yield put(getRecordingListFail(error));
  }
}

function* getRecordingTagSaga({ payload: rqeuserdata }) {
  try {
    const response = yield call(getRecordingTagApi, rqeuserdata);
    console.log(response);
    yield put(getRecordingTagSuccess(GET_RECORDING_TAG, response));
  } catch (error) {
    yield put(getRecordingTagFail(error));
  }
}

function* recordingSaga() {
  yield takeEvery(GET_RECORDING, getRecordingSaga);
  yield takeEvery(GET_RECORDING_LIST, getRecordingListSaga);
  yield takeEvery(GET_RECORDING_TAG, getRecordingTagSaga);
}

export default recordingSaga;
