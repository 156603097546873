import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import {
  GET_RELEASE,
  GET_RELEASE_GROUP,
  GET_RELEASE_GROUP_RELEASE,
  GET_RELEASE_GROUP_URL,
  GET_RELEASE_GROUP_TAG,
  GET_RELEASE_GROUP_TRACK,
  GET_RELEASE_URL,
  GET_RELEASE_TAG,
  GET_RECENT_RELEASE,
  GET_NEW_RELEASE,
  GET_RECENT_RELEASE_FOOTER,
} from "./actionType";

import {
  getReleaseSuccess,
  getReleaseFail,
  getReleaseGroupSuccess,
  getReleaseGroupFail,
  getReleaseGroupReleaseSuccess,
  getReleaseGroupReleaseFail,
  getReleaseGroupUrlSuccess,
  getReleaseGroupUrlFail,
  getReleaseGroupTagSuccess,
  getReleaseGroupTagFail,
  getReleaseGroupTrackSuccess,
  getReleaseGroupTrackFail,
  getReleaseUrlSuccess,
  getReleaseUrlFail,
  getReleaseTagSuccess,
  getReleaseTagFail,
  getRecentReleaseSuccess,
  getRecentReleaseFail,
  getNewReleaseSuccess,
  getNewReleaseFail,
  getRecentReleaseFooterSuccess,
  getRecentReleaseFooterFail,
} from "./action";

import {
  getReleaseApi,
  getReleaseGroupApi,
  getReleaseGroupReleaseApi,
  getReleaseGroupUrlApi,
  getReleaseGroupTagApi,
  getReleaseGroupTrackApi,
  getReleaseUrlApi,
  getReleaseTagApi,
  getRecentReleaseApi,
} from "../../../src/helpers/soundWave_helper";

function* getReleaseSaga({ payload: rqeuserdata }) {
  try {
    const response = yield call(getReleaseApi, rqeuserdata);
    yield put(getReleaseSuccess(GET_RELEASE, response));
  } catch (error) {
    yield put(getReleaseFail(error));
  }
}

function* getReleaseUrlSaga({ payload: rqeuserdata }) {
  try {
    const response = yield call(getReleaseUrlApi, rqeuserdata);
    yield put(getReleaseUrlSuccess(GET_RELEASE_URL, response));
  } catch (error) {
    yield put(getReleaseUrlFail(error));
  }
}

function* getReleaseTagSaga({ payload: rqeuserdata }) {
  try {
    const response = yield call(getReleaseTagApi, rqeuserdata);
    yield put(getReleaseGroupTagSuccess(GET_RELEASE_TAG, response));
  } catch (error) {
    yield put(getReleaseGroupTagFail(error));
  }
}

//releaseGroup
function* getReleaseGroupSaga({ payload: rqeuserdata }) {
  try {
    const response = yield call(getReleaseGroupApi, rqeuserdata);
    yield put(getReleaseGroupSuccess(GET_RELEASE_GROUP, response));
  } catch (error) {
    yield put(getReleaseGroupFail(error));
  }
}

//releaseGroupRelease
function* getReleaseGroupReleaseSaga({ payload: rqeuserdata }) {
  try {
    const response = yield call(getReleaseGroupReleaseApi, rqeuserdata);
    yield put(
      getReleaseGroupReleaseSuccess(GET_RELEASE_GROUP_RELEASE, response)
    );
  } catch (error) {
    yield put(getReleaseGroupReleaseFail(error));
  }
}

//releaseGroupUrl
function* getReleaseGroupUrlSaga({ payload: rqeuserdata }) {
  try {
    const response = yield call(getReleaseGroupUrlApi, rqeuserdata);
    yield put(getReleaseGroupUrlSuccess(GET_RELEASE_GROUP_URL, response));
  } catch (error) {
    yield put(getReleaseGroupUrlFail(error));
  }
}

//releaseGroupTag
function* getReleaseGroupTagSaga({ payload: rqeuserdata }) {
  try {
    const response = yield call(getReleaseGroupTagApi, rqeuserdata);
    yield put(getReleaseGroupTagSuccess(GET_RELEASE_GROUP_TAG, response));
  } catch (error) {
    yield put(getReleaseGroupTagFail(error));
  }
}

//releaseGroupTrack
function* getReleaseGroupTrackSaga({ payload: rqeuserdata }) {
  try {
    const response = yield call(getReleaseGroupTrackApi, rqeuserdata);
    yield put(getReleaseGroupTrackSuccess(GET_RELEASE_GROUP_TRACK, response));
  } catch (error) {
    yield put(getReleaseGroupTrackFail(error));
  }
}

//getRecentReleaseSaga
function* getRecentReleaseSaga({ payload: rqeuserdata }) {
  try {
    const response = yield call(getRecentReleaseApi, rqeuserdata);
    yield put(getRecentReleaseSuccess(GET_RECENT_RELEASE, response));
  } catch (error) {
    yield put(getRecentReleaseFail(error));
  }
}

//getnewReleaseSaga
function* getNewReleaseSaga({ payload: rqeuserdata }) {
  try {
    const response = yield call(getRecentReleaseApi, rqeuserdata);
    yield put(getNewReleaseSuccess(GET_NEW_RELEASE, response));
  } catch (error) {
    yield put(getNewReleaseFail(error));
  }
}

//getnewReleaseSaga
function* getRecentReleaseFooterSaga({ payload: rqeuserdata }) {
  try {
    const response = yield call(getRecentReleaseApi, rqeuserdata);
    console.log(response);
    yield put(
      getRecentReleaseFooterSuccess(GET_RECENT_RELEASE_FOOTER, response)
    );
  } catch (error) {
    yield put(getRecentReleaseFooterFail(error));
  }
}

function* releaseSaga() {
  yield takeEvery(GET_RELEASE, getReleaseSaga);
  yield takeEvery(GET_NEW_RELEASE, getNewReleaseSaga);
  yield takeEvery(GET_RECENT_RELEASE, getRecentReleaseSaga);
  yield takeEvery(GET_RECENT_RELEASE_FOOTER, getRecentReleaseFooterSaga);
  yield takeEvery(GET_RELEASE_URL, getReleaseUrlSaga);
  yield takeEvery(GET_RELEASE_TAG, getReleaseTagSaga);
  yield takeEvery(GET_RELEASE_GROUP, getReleaseGroupSaga);
  yield takeEvery(GET_RELEASE_GROUP_RELEASE, getReleaseGroupReleaseSaga);
  yield takeEvery(GET_RELEASE_GROUP_URL, getReleaseGroupUrlSaga);
  yield takeEvery(GET_RELEASE_GROUP_TAG, getReleaseGroupTagSaga);
  yield takeEvery(GET_RELEASE_GROUP_TRACK, getReleaseGroupTrackSaga);
}

export default releaseSaga;
