import React, { useEffect, useState } from "react";
import { TbUserPentagon } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Col, Row } from "reactstrap";
import { Grid, _ } from "gridjs-react";
import "./ReleaseGroup.css";
import { MdLibraryMusic } from "react-icons/md";
import {
  getRelease,
  getReleaseGroup,
  getReleaseGroupRelease,
  getReleaseGroupTag,
  getReleaseGroupTrack,
  getReleaseGroupUrl,
  resetRelease,
  resetReleaseGroup,
  resetgetReleaseGroupRelease,
  resetgetReleaseGroupTag,
} from "../../Store/actions";
import { useNavigate, useParams } from "react-router-dom";

const ReleaseGroup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { gid } = useParams();

  console.log("gid of artist", gid);

  useEffect(() => {
    const timer = setTimeout(() => {
      let reqdata = {
        release_group_gid: gid,
      };

      let reqdata1 = {
        release_group_gid: gid,
        page: 1,
      };

      dispatch(getReleaseGroup(reqdata));
      dispatch(getReleaseGroupTag(reqdata));
      dispatch(getReleaseGroupUrl(reqdata));
      dispatch(getReleaseGroupRelease(reqdata1));
    }, 0);

    return () => clearTimeout(timer); // Cleanup on unmount
  }, [gid]); // Empty dependency array means it should only run once

  const {
    releaseGroupData,
    releaseGroupUrlData,
    releaseGroupTagData,
    releaseGroupReleaseData,

    releaseCode,
    releaseGroupCode,
    releaseGroupUrlCode,
    releaseGroupReleaseCode,
    releaseGroupTagCode,
  } = useSelector((state) => ({
    releaseGroupData: state.releaseReducer.releaseGroupData,
    releaseGroupUrlData: state.releaseReducer.releaseGroupUrlData,
    releaseGroupTagData: state.releaseReducer.releaseGroupTagData,
    releaseGroupReleaseData: state.releaseReducer.releaseGroupReleaseData,

    releaseCode: state.releaseReducer.releaseCode,
    releaseGroupCode: state.releaseReducer.releaseGroupCode,
    releaseGroupUrlCode: state.releaseReducer.releaseGroupUrlCode,
    releaseGroupReleaseCode: state.releaseReducer.releaseGroupReleaseCode,
    releaseGroupTagCode: state.releaseReducer.releaseGroupTagCode,
  }));

  const [releaseGroupInfo, setreleaseGroupInfo] = useState({
    release_group_id: "",
    release_group_gid: "",
    name: "",
    type: "",
    artist_credit_id: "",
    cover_art_url: "",
    artist_credit_names: [],
  });
  const [releaseGroupTag, setreleaseGroupTag] = useState([]);
  const [releaseGroupRelease, setreleaseGroupRelease] = useState([]);

  useEffect(() => {
    if (releaseGroupTagCode == 200) {
      const groupedData = releaseGroupTagData.reduce((result, item) => {
        // Check if the group (item.type) already exists in the result
        if (!result[item.type]) {
          result[item.type] = []; // If not, create an empty array for this group
        }
        // Add the item to the appropriate group
        result[item.type].push(item);
        return result;
      }, {});

      console.log(groupedData);
      setreleaseGroupTag(groupedData);

      dispatch(resetgetReleaseGroupTag());
    }
  }, [releaseGroupTagCode]);

  useEffect(() => {
    if (releaseGroupCode == 200) {
      setreleaseGroupInfo(releaseGroupData);
      dispatch(resetReleaseGroup());
    }
  }, [releaseGroupCode]);

  useEffect(() => {
    if (releaseGroupReleaseCode == 200) {
      setreleaseGroupRelease(releaseGroupReleaseData);
      dispatch(resetgetReleaseGroupRelease());
    }
  }, [releaseGroupReleaseCode]);

  const handleReleaseGroupClick = (cell, row, id) => {
    let reqdata = {
      release_gid: id,
    };

    navigate(`/release/${id}`, {
      state: {
        release_gid: id,
      },
    });
  };

  const handleArtistNameClick = (id) => {
    navigate(`/artist/${id}`);
  };

  return (
    <Row>
      <Col md="2">
        <div className="leftMainDiv">
          <div>
            <p className="infoHeading">Release group information</p>
            <p className="infoText">
              <strong>Sort name:</strong> {releaseGroupInfo.name}
            </p>
            <p className="infoText">
              <strong>Type:</strong> {releaseGroupInfo.type}
            </p>
          </div>
          <div>
            <p className="infoHeading">Tags</p>
            {Object.entries(releaseGroupTag).map(([groupName, items]) => (
              <div key={groupName}>
                <strong>{groupName}</strong>
                {items.map((item) => (
                  <p style={{ margin: 0 }} key={item.tag_id}>
                    {item.name}
                  </p>
                ))}
              </div>
            ))}
          </div>
        </div>
      </Col>

      <Col md="10" style={{ borderLeft: "1px solid #ccc" }}>
        <div className="nameDiv">
          <img src={releaseGroupInfo.cover_art_url} className="coverImage" />
          <div>
            <strong className="artistName">{releaseGroupInfo.name}</strong>
            <p style={{ margin: 0 }}>
              ~Release group by{" "}
              {releaseGroupInfo &&
                releaseGroupInfo.artist_credit_names &&
                releaseGroupInfo.artist_credit_names.map((i, k) => (
                  <>
                    <span
                      style={{ margin: 0, color: "#5B7EF0", cursor: "pointer" }}
                      onClick={() => handleArtistNameClick(i.artist_gid)}
                    >
                      {i.name}{" "}
                    </span>
                    {i.join_phrase}{" "}
                  </>
                ))}
            </p>
          </div>
        </div>
        <Tabs
          style={{ marginTop: "30px" }}
          defaultIndex={0}
          //onSelect={(index) => handleTabChange(index)}
        >
          <TabList>
            <Tab>Overview</Tab>
            <Tab>Tags</Tab>
          </TabList>
          <TabPanel>
            <div>
              <p
                style={{
                  color: "rgb(91, 126, 240)",
                  marginTop: "15px",
                  marginBottom: "15px",
                  fontWeight: "700",
                  fontSize: "20px",
                }}
              >
                Album
              </p>
              <Grid
                data={releaseGroupRelease}
                columns={[
                  {
                    id: "gid",
                    name: "gid",
                    hidden: true,
                  },

                  {
                    id: "artist_credit_names",
                    name: "artist_credit_names",
                    hidden: true,
                  },
                  {
                    id: "country_name",
                    name: "country_name",
                    hidden: true,
                  },
                  {
                    id: "date_year",
                    name: "date_year",
                    hidden: true,
                  },
                  {
                    id: "date_month",
                    name: "date_month",
                    hidden: true,
                  },
                  {
                    id: "date_day",
                    name: "date_day",
                    hidden: true,
                  },
                  {
                    id: "name",
                    name: "Release",
                    formatter: (cell, row) =>
                      _(
                        <div>
                          <p
                            className="linkHover"
                            style={{ cursor: "pointer", margin: 0 }}
                            onClick={() =>
                              handleReleaseGroupClick(
                                cell,
                                row,
                                row.cells[0]["data"]
                              )
                            }
                          >
                            {cell}
                          </p>
                        </div>
                      ),
                  },
                  {
                    id: "name",
                    name: "Artist",
                    formatter: (cell, row) =>
                      _(
                        <div>
                          <p style={{ cursor: "pointer", margin: 0 }}>
                            {row.cells[1]["data"].map((i, k) => (
                              <>
                                <label
                                  className="linkHover"
                                  style={{ cursor: "pointer", margin: 0 }}
                                  key={k}
                                  onClick={() =>
                                    handleArtistNameClick(i.artist_gid)
                                  }
                                >
                                  {i.name}
                                  {k != row.cells[1]["data"].length - 1 && ", "}
                                </label>
                              </>
                            ))}
                          </p>
                        </div>
                      ),
                  },
                  {
                    id: "format",
                    name: "Format",
                  },
                  {
                    id: "track_count",
                    name: "Tracks",
                  },
                  {
                    id: "Country/Date",
                    name: "Country/Date",
                    formatter: (cell, row) =>
                      _(
                        <div>
                          <p style={{ margin: 0 }}>
                            {row.cells[2]["data"]} {row.cells[3]["data"]}-
                            {row.cells[4]["data"]}-{row.cells[5]["data"]}
                          </p>
                        </div>
                      ),
                  },
                  {
                    id: "barcode",
                    name: "Barcode",
                  },
                ]}
                search={true}
                // sort={true}
                // height={height}
                // style={{ height: { height }, overflowY: "scroll" }}
                pagination={{ enabled: true, limit: 25 }}
                autoWidth={true}
              />
            </div>
          </TabPanel>
          <TabPanel>
            <div>
              <p className="infoHeading">Tags</p>
              {Object.entries(releaseGroupTag).map(([groupName, items]) => (
                <div key={groupName}>
                  <strong>{groupName}</strong>
                  {items.map((item) => (
                    <p style={{ margin: 0 }} key={item.tag_id}>
                      {item.name}
                    </p>
                  ))}
                </div>
              ))}
            </div>
          </TabPanel>
        </Tabs>
      </Col>
    </Row>
  );
};

export default ReleaseGroup;
