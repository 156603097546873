import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getArtist,
  getRecentRelease,
  resetRecentRelease,
} from "../Store/actions";
import { useDispatch, useSelector } from "react-redux";
import "../css/Home.css";
import image1 from "../../src/assets/image1.png";
import image2 from "../../src/assets/image2.png";
import image3 from "../../src/assets/image3.png";
import image4 from "../../src/assets/image4.png";
import image5 from "../../src/assets/image5.png";
import image6 from "../../src/assets/image6.png";
import image7 from "../../src/assets/image7.png";
import image8 from "../../src/assets/image8.png";
import ImageWithFallback from "../Component/ImageWithFallback";

const Home = () => {
  const imageData = [
    { src: image1 },
    { src: image2 },
    { src: image3 },
    { src: image4 },
    { src: image5 },
    { src: image6 },
    { src: image7 },
    { src: image8 },
  ];

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [recentRelease, setrecentRelease] = useState([]);

  const { artistData, recentReleaseData, recentReleaseCode } = useSelector(
    (state) => ({
      artistData: state.artistReducer.artistData,
      recentReleaseData: state.releaseReducer.recentReleaseData,
      recentReleaseCode: state.releaseReducer.recentReleaseCode,
    })
  );

  console.log("recentReleaseData=======", recentReleaseData);
  console.log("recentReleaseCode=======", recentReleaseCode);

  const goToAbout = () => {
    navigate("/about", { state: { userName: "Sumit" } });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(getRecentRelease());
    }, 0);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (recentReleaseCode == 200) {
      setrecentRelease(recentReleaseData);
      dispatch(resetRecentRelease());
    }
  }, [recentReleaseCode]);

  return (
    <>
      <div className="mainDiv">
        <p className="heading">
          Welcome to Sound<label style={{ color: "#5B7EF0" }}>Wave!</label>
        </p>
        <p className="releaseText">Releases</p>
        <div className="imageDiv">
          {recentRelease.map((i, k) => (
            <ImageWithFallback
              src={i.cover_art_url}
              alt={i.name}
              style={{ width: "400px", height: "400px", borderRadius: "5px" }}
              item={i}
            />
          ))}
          {/* <img className="image" src={i.cover_art_url} key={k} alt={i.name} /> */}
        </div>
      </div>
    </>
  );
};

export default Home;
