import React, { useEffect, useState } from "react";
import CommonNewGrid from "../../Component/CommonNewGrid";
import { useDispatch, useSelector } from "react-redux";
import { Grid, _ } from "gridjs-react";
import "gridjs/dist/theme/mermaid.css";
import { resetSearch } from "../../Store/actions";
import { Card, CardBody } from "reactstrap";
import { useNavigate } from "react-router-dom";

const SearchRecording = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { searchData, searchCode, searchType } = useSelector((state) => ({
    searchData: state.searchReducer.searchData,
    searchCode: state.searchReducer.searchCode,
    searchType: state.searchReducer.searchType,
  }));

  const [recordingData, setrecordingData] = useState([]);

  console.log("searchData==========", searchData);

  useEffect(() => {
    if (Array.isArray(searchData) && searchType == "recording") {
      setrecordingData(searchData);
      dispatch(resetSearch());
    }
  }, [searchData]);

  const handleRecordingClick = (cell, row, id) => {
    console.log("cell========", cell);
    console.log("row========", row);
    console.log("id========", id);

    let reqdata = {
      release_group_gid: id,
    };

    navigate(`/recording/${id}`);
  };

  const handleArtistClick = (cell, row, id, type) => {
    navigate(`/artist/${id}`, {
      state: {
        artistName: cell,
        artistType: type,
        artistId: id,
      },
    });
  };

  function convertMillisecondsToMinutes(milliseconds) {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    // Pad single-digit seconds with a leading zero
    const formattedSeconds = seconds.toString().padStart(2, "0");

    return `${minutes}:${formattedSeconds}`;
  }

  const BaseExample = () => {
    return (
      <React.Fragment>
        <Grid
          data={recordingData}
          columns={[
            {
              id: "recording_gid",
              name: "recording_gid",
              hidden: true,
            },
            {
              id: "release_artists",
              name: "release_artists",
              hidden: true,
            },
            {
              id: "recording",
              name: "Name",
              formatter: (cell, row) =>
                _(
                  <div>
                    <p
                      className="linkHover"
                      style={{ cursor: "pointer", margin: 0 }}
                      onClick={() =>
                        handleRecordingClick(cell, row, row.cells[0]["data"])
                      }
                    >
                      {cell}
                    </p>
                  </div>
                ),
            },
            {
              id: "length",
              name: "Length",
              formatter: (cell, row) =>
                _(
                  <div>
                    <p style={{ margin: 0 }}>
                      {convertMillisecondsToMinutes(cell)}
                    </p>
                  </div>
                ),
            },
            {
              id: "recording",
              name: "Name",
              formatter: (cell, row) =>
                _(
                  <div>
                    {row.cells[1]["data"].map((i, k) => (
                      <>
                        <span
                          style={{ cursor: "pointer", margin: 0 }}
                          className="linkHover"
                          onClick={() =>
                            handleArtistClick(
                              cell,
                              row,
                              i.artist_gid,
                              row.cells[3]["data"]
                            )
                          }
                        >
                          {i.name}
                        </span>
                        {i.join_phrase}
                      </>
                    ))}
                  </div>
                ),
            },
            {
              id: "isrc",
              name: "ISRCs",
            },
            {
              id: "release_title",
              name: "Release",
              formatter: (cell, row) =>
                _(
                  <div>
                    <p
                      className="linkHover"
                      style={{ cursor: "pointer", margin: 0 }}
                      onClick={() =>
                        handleRecordingClick(cell, row, row.cells[0]["data"])
                      }
                    >
                      {cell}
                    </p>
                  </div>
                ),
            },
            {
              id: "rg_primary_type",
              name: "Type",
            },
          ]}
          search={true}
          // sort={true}
          // height={height}
          // style={{ height: { height }, overflowY: "scroll" }}
          pagination={{ enabled: true, limit: 25 }}
        />
      </React.Fragment>
    );
  };

  return (
    <>
      <div>
        <h3>Search Result</h3>
      </div>
      <Card>
        <CardBody>
          <BaseExample />
        </CardBody>
      </Card>
    </>
  );
};

export default SearchRecording;
