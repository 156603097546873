export const GET_RECORDING = "GET_RECORDING";
export const GET_RECORDING_SUCCESS = "GET_RECORDING_SUCCESS";
export const GET_RECORDING_FAIL = "GET_RECORDING_FAIL";

export const GET_RECORDING_LIST = "GET_RECORDING_LIST";
export const GET_RECORDING_LIST_SUCCESS = "GET_RECORDING_LIST_SUCCESS";
export const GET_RECORDING_LIST_FAIL = "GET_RECORDING_LIST_FAIL";

export const GET_RECORDING_TAG = "GET_RECORDING_TAG";
export const GET_RECORDING_TAG_SUCCESS = "GET_RECORDING_TAG_SUCCESS";
export const GET_RECORDING_TAG_FAIL = "GET_RECORDING_TAG_FAIL";

export const RESET_RECORDING = "RESET_RECORDING";
export const RESET_RECORDING_LIST = "RESET_RECORDING_LIST";
export const RESET_RECORDING_TAG = "RESET_RECORDING_TAG";
