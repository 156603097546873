import {
  GET_RECORDING,
  GET_RECORDING_SUCCESS,
  GET_RECORDING_FAIL,
  GET_RECORDING_LIST,
  GET_RECORDING_LIST_SUCCESS,
  GET_RECORDING_LIST_FAIL,
  RESET_RECORDING,
  RESET_RECORDING_LIST,
  GET_RECORDING_TAG,
  GET_RECORDING_TAG_SUCCESS,
  GET_RECORDING_TAG_FAIL,
  RESET_RECORDING_TAG,
} from "./actionType";

export const getRecording = (payload) => ({
  type: GET_RECORDING,
  payload: payload,
});

export const getRecordingSuccess = (actionType, data) => ({
  type: GET_RECORDING_SUCCESS,
  payload: { actionType, data },
});

export const getRecordingFail = (actionType, error) => ({
  type: GET_RECORDING_FAIL,
  payload: { actionType, error },
});

export const resetRecording = () => ({
  type: RESET_RECORDING,
});

//GET_RECORDING_LIST
export const getRecordingList = (payload) => ({
  type: GET_RECORDING_LIST,
  payload: payload,
});

export const getRecordingListSuccess = (actionType, data) => ({
  type: GET_RECORDING_LIST_SUCCESS,
  payload: { actionType, data },
});

export const getRecordingListFail = (actionType, error) => ({
  type: GET_RECORDING_LIST_FAIL,
  payload: { actionType, error },
});

export const resetRecordingList = () => ({
  type: RESET_RECORDING_LIST,
});

//tag
export const getRecordingTag = (payload) => ({
  type: GET_RECORDING_TAG,
  payload: payload,
});

export const getRecordingTagSuccess = (actionType, data) => ({
  type: GET_RECORDING_TAG_SUCCESS,
  payload: { actionType, data },
});

export const getRecordingTagFail = (actionType, error) => ({
  type: GET_RECORDING_TAG_FAIL,
  payload: { actionType, error },
});

export const resetRecordingTag = () => ({
  type: RESET_RECORDING_TAG,
});
