import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import {
  GET_ARTIST,
  GET_ARTIST_RELEASE,
  GET_ARTIST_RELEASE_GROUP,
  GET_ARTIST_TAG,
  GET_ARTIST_URL,
  GET_ARTIST_RELATIONSHIP,
} from "./actionType";

import {
  getArtistSuccess,
  getArtistFail,
  getArtistReleaseFail,
  getArtistReleaseSuccess,
  getArtistReleaseGroupSuccess,
  getArtistReleaseGroupFail,
  getArtistTagSuccess,
  getArtistTagFail,
  getArtistUrlSuccess,
  getArtistUrlFail,
  getArtistRelationshipSuccess,
  getArtistRelationshipFail,
} from "./action";

import {
  getArtistApi,
  getArtistReleaseGroupApi,
  getArtistReleaseApi,
  getArtistTagApi,
  getArtistUrlApi,
  getArtistRelationShipApi,
} from "../../helpers/soundWave_helper";

function* getArtistSaga({ payload: rqeuserdata }) {
  try {
    const response = yield call(getArtistApi, rqeuserdata);
    yield put(getArtistSuccess(GET_ARTIST, response));
  } catch (error) {
    yield put(getArtistFail(error));
  }
}

function* getArtistRelationshipSaga({ payload: rqeuserdata }) {
  try {
    const response = yield call(getArtistRelationShipApi, rqeuserdata);
    yield put(getArtistRelationshipSuccess(GET_ARTIST_RELATIONSHIP, response));
  } catch (error) {
    yield put(getArtistRelationshipFail(error));
  }
}

//release
function* getArtistReleaseSaga({ payload: rqeuserdata }) {
  try {
    const response = yield call(getArtistReleaseApi, rqeuserdata);
    yield put(getArtistReleaseSuccess(GET_ARTIST_RELEASE, response));
  } catch (error) {
    yield put(getArtistReleaseFail(error));
  }
}

//release-group
function* getArtistReleaseGroupSaga({ payload: rqeuserdata }) {
  try {
    const response = yield call(getArtistReleaseGroupApi, rqeuserdata);
    yield put(getArtistReleaseGroupSuccess(GET_ARTIST_RELEASE_GROUP, response));
  } catch (error) {
    yield put(getArtistReleaseGroupFail(error));
  }
}

//url
function* getArtistUrlSaga({ payload: rqeuserdata }) {
  try {
    const response = yield call(getArtistUrlApi, rqeuserdata);
    yield put(getArtistUrlSuccess(GET_ARTIST_URL, response));
  } catch (error) {
    yield put(getArtistUrlFail(error));
  }
}

//tag
function* getArtistTagSaga({ payload: rqeuserdata }) {
  try {
    const response = yield call(getArtistTagApi, rqeuserdata);
    yield put(getArtistTagSuccess(GET_ARTIST_TAG, response));
  } catch (error) {
    yield put(getArtistTagFail(error));
  }
}

function* artistSaga() {
  yield takeEvery(GET_ARTIST, getArtistSaga);
  yield takeEvery(GET_ARTIST_RELEASE, getArtistReleaseSaga);
  yield takeEvery(GET_ARTIST_RELEASE_GROUP, getArtistReleaseGroupSaga);
  yield takeEvery(GET_ARTIST_URL, getArtistUrlSaga);
  yield takeEvery(GET_ARTIST_TAG, getArtistTagSaga);
  yield takeEvery(GET_ARTIST_RELATIONSHIP, getArtistRelationshipSaga);
}

export default artistSaga;
