import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { GET_SEARCH } from "./actionType";

import { getSearchSuccess, getSearchFail } from "./action";

import { searchApi } from "../../helpers/soundWave_helper";

function* getSearchSaga({ payload: rqeuserdata }) {
  try {
    const response = yield call(searchApi, rqeuserdata);
    console.log(response);
    yield put(getSearchSuccess(GET_SEARCH, response));
  } catch (error) {
    yield put(getSearchFail(error));
  }
}

function* searchSaga() {
  yield takeEvery(GET_SEARCH, getSearchSaga);
}

export default searchSaga;
