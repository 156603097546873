import React, { useEffect, useState } from "react";
import CommonNewGrid from "../../Component/CommonNewGrid";
import { useDispatch, useSelector } from "react-redux";
import { Grid, _ } from "gridjs-react";
import "gridjs/dist/theme/mermaid.css";
import {
  getArtist,
  getArtistRelease,
  getArtistReleaseGroup,
  getArtistTag,
  getArtistUrl,
  resetArtist,
  resetSearch,
} from "../../Store/actions";
import { Card, CardBody } from "reactstrap";
import { useNavigate } from "react-router-dom";

const SearchReleaseGroup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { searchData, searchCode, artistCode, searchType } = useSelector(
    (state) => ({
      searchData: state.searchReducer.searchData,
      searchCode: state.searchReducer.searchCode,
      artistCode: state.artistReducer.artistCode,
      searchType: state.searchReducer.searchType,
    })
  );

  const [artistSearchData, setartistSearchData] = useState([]);

  console.log("searchData==========", searchData);

  useEffect(() => {
    if (Array.isArray(searchData) && searchType == "release_group") {
      setartistSearchData(searchData);
      dispatch(resetSearch());
    }
  }, [searchData]);

  const [artistName, setartistName] = useState(null);
  const [artistType, setartistType] = useState(null);
  const [artistId, setartistId] = useState(null);

  const handleReleaseGroupClick = (cell, row, id) => {
    console.log("cell========", cell);
    console.log("row========", row);
    console.log("id========", id);

    let reqdata = {
      release_group_gid: id,
    };

    navigate(`/releaseGroup/${id}`, {
      state: {
        release_group_gid: id,
      },
    });
  };

  const handleArtistClick = (cell, row, id, type) => {
    navigate(`/artist/${id}`, {
      state: {
        artistName: cell,
        artistType: type,
        artistId: id,
      },
    });
  };

  // useEffect(() => {
  //   console.log(artistCode);
  //   if (artistCode == 200) {
  //     navigate("/artist", {
  //       state: {
  //         artistName: artistName,
  //         artistType: artistType,
  //         artistId: artistId,
  //       },
  //     });
  //     // dispatch(resetArtist());
  //   }
  // }, [artistCode]);

  const BaseExample = () => {
    return (
      <React.Fragment>
        <Grid
          data={artistSearchData}
          columns={[
            {
              id: "release_group_gid",
              name: "release_group_gid",
              hidden: true,
            },
            {
              id: "artist_credit_names",
              name: "artist_credit_names",
              hidden: true,
            },
            {
              id: "release_group_name",
              name: "Release group",
              formatter: (cell, row) =>
                _(
                  <div>
                    <p
                      className="linkHover"
                      style={{ cursor: "pointer", margin: 0 }}
                      onClick={() =>
                        handleReleaseGroupClick(cell, row, row.cells[0]["data"])
                      }
                    >
                      {cell}
                    </p>
                  </div>
                ),
            },
            {
              id: "Artist",
              name: "Artist",
              formatter: (cell, row) =>
                _(
                  <div>
                    {row.cells[1]["data"].map((i, k) => (
                      <>
                        <span
                          style={{ cursor: "pointer", margin: 0 }}
                          className="linkHover"
                          onClick={() =>
                            handleArtistClick(
                              cell,
                              row,
                              i.artist_gid,
                              row.cells[3]["data"]
                            )
                          }
                        >
                          {i.name}
                        </span>
                        {i.join_phrase}
                      </>
                    ))}
                  </div>
                ),
            },

            {
              id: "type",
              name: "Type",
            },
          ]}
          search={true}
          // sort={true}
          // height={height}
          // style={{ height: { height }, overflowY: "scroll" }}
          pagination={{ enabled: true, limit: 25 }}
        />
      </React.Fragment>
    );
  };

  return (
    <>
      <div>
        <h3>Search Result</h3>
      </div>
      <Card>
        <CardBody>
          <BaseExample />
        </CardBody>
      </Card>
    </>
  );
};

export default SearchReleaseGroup;
