// src/Header.js
import React, { useEffect, useState } from "react";
import "./Header.css"; // Optional: import CSS for styling
import { FaUser } from "react-icons/fa";
import { GiSoundWaves } from "react-icons/gi";
import { FaSearch } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getSearch, resetSearch } from "../../Store/actions";
import { useNavigate } from "react-router-dom";
import { Bars } from "react-loader-spinner";

const Header = ({ links = [] }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectType, setselectType] = useState("artist");
  const [searchQuery, setsearchQuery] = useState("");

  const { searchData, searchCode, searchType } = useSelector((state) => ({
    searchData: state.searchReducer.searchData,
    searchCode: state.searchReducer.searchCode,
    searchType: state.searchReducer.searchType,
  }));

  const handleInput = (e) => {
    setsearchQuery(e);
  };

  const handleSelect = (e) => {
    setselectType(e);
  };

  const [isLoader, setisLoader] = useState(false);

  const handleSearch = () => {
    setisLoader(true);
    let reqdata = {
      query: searchQuery,
      type: selectType,
      limit: 1000,
    };
    dispatch(getSearch(reqdata));
  };

  useEffect(() => {
    if (searchCode == 200 && searchType == "artist") {
      console.log(searchCode);
      // dispatch(resetSearch());
      navigate("/searchArtist");
      setisLoader(false);
    } else if (searchCode == 200 && searchType == "release") {
      console.log(searchCode);
      // dispatch(resetSearch());
      navigate("/searchRelease");
      setisLoader(false);
    } else if (searchCode == 200 && searchType == "release_group") {
      console.log(searchCode);
      // dispatch(resetSearch());
      navigate("/searchReleaseGroup");
      setisLoader(false);
    } else if (searchCode == 200 && searchType == "recording") {
      console.log(searchCode);
      // dispatch(resetSearch());
      navigate("/searchRecording");
      setisLoader(false);
    }

    if (searchCode == 200) {
      // setselectType("artist");
      // setsearchQuery("");
    }
  }, [searchCode]);

  // Handle Enter key press in input
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && searchQuery.trim()) {
      handleSearch(); // Call API when Enter is pressed
    }
  };

  return (
    <header className="header">
      <div
        className="header-logo"
        style={{ cursor: "pointer" }}
        onClick={() => {
          navigate("/");
        }}
      >
        <GiSoundWaves color="#5B7EF0" size={"50px"} />
        <h1 className="headerHeading">
          Sound<label className="headerHeading1">Wave</label>
        </h1>
      </div>
      <div className="searchDiv">
        <select
          value={selectType}
          className="searchSelect"
          placeholder="Search"
          defaultValue={"artist"}
          onChange={(e) => handleSelect(e.target.value)}
        >
          <option value={"artist"}>Artist</option>
          <option value={"release"}>Release</option>
          <option value={"recording"}>Recording</option>
          <option value={"release_group"}>Release group</option>
        </select>

        <input
          value={searchQuery}
          className="searchInput"
          placeholder="Search"
          onChange={(e) => handleInput(e.target.value)}
          onKeyDown={(e) => handleKeyDown(e)}
        />

        {isLoader == true ? (
          <Bars
            height="25"
            width="25"
            color="#5B7EF0"
            ariaLabel="bars-loading"
            wrapperClass=""
            visible={true}
          />
        ) : (
          <FaSearch
            color="#5B7EF0"
            size={"20px"}
            cursor={"pointer"}
            onClick={() => handleSearch()}
          />
        )}
      </div>

      <div className="loginDiv">
        <div className="loginIconDiv">
          <FaUser color="#5B7EF0" />
        </div>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/login");
          }}
        >
          <p className="loginText">Login/Signup</p>
        </div>
      </div>
    </header>
  );
};

export default Header;
