module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  API_URL: {
    // API_URL: "http://192.168.0.30:8025/api/p",
    // API_URL: "http://103.106.21.49:8025/api/p",
    API_URL: "https://soundwavebe.avanzarsolution.com/api/p",
    //API_URL : ""
  },
  API_Report: {
    // Report_URL: "http://192.168.0.30:8025/api/p",
    // Report_URL: "http://103.106.21.49:8025/api/p",
    Report_URL: "https://soundwavebe.avanzarsolution.com/api/p",
    //API_URL : ""
  },
  API_IMG: {
    // IMG_URL: "http://192.168.0.30:8025/api/p",
    // IMG_URL: "http://103.106.21.49:8025/api/p",
    IMG_URL: "https://soundwavebe.avanzarsolution.com/api/p",
  },

  Title_Name: "SoundWave",
};
